@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts' as *;
.css-1m7nvsi-MuiGrid-root {
  min-height: 80px !important;
}
.vs {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-shrink: 0;
  font-family: 'Public Sans', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  user-select: none;
  color: $info-700;
  background-color: rgba(124, 130, 138, 0.16);
  height: 24px;
  width: 24px;
  font-size: 0.6875rem;
}
.css-1m7nvsi-MuiGrid-root {
  gap: 0.5rem;
  display: flex;
  -webkit-box-align: center;
  align-items: flex-start !important;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.css-dehon9-MuiGrid-root {
  flex-grow: 0 !important;
}
.home-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  .home {
    display: flex;
    flex-direction: column;
    &-header {
      display: flex;
      align-items: center;
      gap: 0.3rem;
      &-title {
        color: $secondary-500;
      }
      &-logo {
        & svg {
          width: 28px;
        }
      }
    }
    &-body {
      display: flex;
      align-items: center;
      & p {
        white-space: nowrap;
      }
    }
  }
}

@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts' as *;

@use 'src/components' as *;
@use 'src/features' as *;
@use 'src/layout' as *;

@forward './dark.scss';

.App {
  min-width: 100vw;
  min-height: 100vh;
  background-color: $secondary-100;
}
.auth_loader {
  height: 70vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.local-storage-button {
  background-color: red;
  color: white;
  font-size: 15px;
  padding: 15px 32px;
  cursor: pointer;
  border-radius: 15px;
}

@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts' as *;
@forward './components/index';

.dashboard_layout_container {
  height: 100vh;
  display: flex;

  .content_container {
    width: 100%;
    padding: 32px 22px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 22px;
  }

  .sidebar {
    z-index: 999;
  }

  @media only screen and (max-width: 768px) {
    .sidebar {
      position: absolute;
      top: 0;
      left: -300px;
      height: 100vh;
      transition: all 0.35s ease-out;
    }

    .toggle-mobile-sidebar {
      left: 0;
    }
  }
}

@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts' as *;
@forward './components/index';

.card_container {
  background-color: $light;
  border-radius: 12px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  .table_head {
    background-color: $secondary-100;
  }

  .delete_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    padding: 8px 0;

    .delete_btn {
      min-height: 30px !important;
    }
  }

  @media only screen and (max-width: 480px) {
    .card_header {
      flex-direction: column-reverse;
    }
  }
}

.create_button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
}

.action_buttons {
  display: flex;

  align-items: center;
  gap: 22px;
}

.table_head_label {
  white-space: nowrap;
}

.table_head_sort {
  display: flex;
  justify-content: center;
  align-items: center;
}

.darg-and-drop-icon {
  padding: 6px 12px;
  cursor: pointer;
}

.reset-no-data-found-btn {
  cursor: pointer;
  background-color: transparent;
}

.update_container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 0 22px 8px 22px;

  .update_btn {
    min-height: 32px !important;
    &:hover {
      transform: translateY(0em) !important;
    }
  }
}

.table_loading {
  position: relative;
}

.table_loading_active {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;

  display: flex;
  justify-content: center;
  align-items: center;
}

.css-1yhpg23-MuiTableCell-root > div > i {
  cursor: pointer;
  width: 45%;
  display: flex;
  justify-content: flex-end;
}
.Mui-checked + .MuiSwitch-track {
  background-color: #4f46e5 !important;
}
.Mui-checked {
  .MuiSwitch-thumb {
    color: #4f46e5 !important;
  }
}
.date-filter{
  width: 100%;
  display: flex;
  align-items: center;

padding-right: 23px;
padding-bottom: 20px;
  justify-content: end;
  gap: 10px;
  flex-wrap: wrap;
}
@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts' as *;

.nav_item_container {
  .sub_header {
    font-size: 14px;
    color: $secondary-400;
    text-transform: uppercase;
    margin-bottom: 18px;

    .icon_color {
      color: $secondary-400;
    }
  }

  .item {
    color: $secondary-600;
    margin: 6px 0;
    padding: 12px 15px 8px 15px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;

    .icon_color {
      color: $secondary-600;
      padding-bottom: 3px;
    }

    &:hover {
      background-color: $primary-500;
      color: $light;

      .icon_color {
        color: $light;
      }
    }
  }

  .active {
    background-color: $primary;
    color: $light;

    .icon_color {
      color: $light;
    }
  }
}

@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts' as *;

.eye_btn_color {
  cursor: pointer;
  color: $secondary-600;

  &:hover {
    color: $secondary-400;
  }
}

.modal_container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 22px;
}

.info_container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  gap: 22px;
}

.modal_image {
  max-width: 100%;
  max-height: 300px;
}

.delivery_stats-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.delivery_stats {
  .button_container {
    margin-top: 30px;
  }
}

@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts' as *;

.alert {
  width: 100%;
  padding: 12px 20px;
  border-radius: 0.25rem;
}

@mixin alert-bg($bg) {
  background-color: $bg;
}

.alert-primary {
  @include alert-bg($primary);
}

.alert-secondary {
  @include alert-bg($secondary-400);
}

.alert-danger {
  @include alert-bg($danger-500);
}

.alert-warning {
  @include alert-bg($warning);
}

.alert-success {
  @include alert-bg($success);
}

.alert-info {
  @include alert-bg($info);
}

.light_color {
  color: $light;
}

@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts' as *;
.product_container {
  border: 1px solid $secondary-200;
  background-color: $light;
  border-radius: 8px;
  padding: 10px 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &_det {
    display: flex;
    gap: 1.2rem;
    align-items: center;
    &_img {
      width: 75px;
    }
    &_container {
      display: flex;
      flex-direction: column;
      &_name,
      &_price {
        font-family: inherit;
        font-weight: 400;
        font-size: 1rem;
        color: $secondary-400;
      }
    }
  }
  &_quantity {
    font-family: inherit;
    font-weight: 400;
    font-size: 1rem;
    color: $secondary-400;
  }
}
@media (max-width: '340px') {
  .product_container {
    &_det {
      &_img {
        width: 40px;
      }
    }
  }
}

#products_container {
  .products_form_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
  }

  .products_form_button {
    width: 130px;
    margin-top: 12px;
  }
}

.leaflet-container {
  width: 100%;
  height: 600px;
}

.leaflet-bottom.leaflet-right {
  width: 0 !important;
  height: 0 !important;
}
.easy-button-button .button-state {
  height: unset !important;
}
.leaflet-top,
.leaflet-bottom {
  z-index: unset !important;
}
.map-true {
  position: relative;
  .leaflet-container {
    opacity: 0.5;
  }
  .loading {
    position: absolute;
    height: 100%;
    z-index: 999;
  }
}

@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts' as *;
.info-disp {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  &-header {
    margin-bottom: 0.625rem;
    gap: 0.5rem;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    &-title {
      margin: 0px;
      font-family: 'Public Sans', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol';
      font-weight: 550;
      font-size: 1rem;
      line-height: 1.5;
      color: $info-700;
    }
  }
  &-info {
    white-space: nowrap;
    margin: 0px;
    font-family: 'Public Sans', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.6;
    color: $info-800;
  }
  &-value {
    margin: 0px;
    font-family: 'Public Sans', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-weight: 700;
    font-size: 0.675rem;
    line-height: 1.43;
    color: $info-600;
  }
}

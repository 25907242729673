@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts' as *;

.columns_container {
  padding: 0 22px 12px 22px;
}

.MuiSwitch-thumb {
  color: $secondary-100;
}
.css-jsexje-MuiSwitch-thumb {
  color: $secondary-100;
}

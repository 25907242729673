@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts' as *;
.switch-field {
  display: flex;
  justify-content: center;
}

.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;

  overflow: hidden;
}

.switch-field label {
  background-color: $secondary-100;
  font-size: 14px;
  line-height: 1;
  text-align: center;
  padding: 4px;

  box-shadow: inset 0 1px 3px rgba(255, 255, 255, 0.1);

  transition: all 0.1s ease-in-out;
}

.switch-field label:hover {
  cursor: pointer;
}

.switch-field input:checked + label {
  background-color: #e4e4e4;
  box-shadow: inset 0 1px 3px rgba(255, 255, 255, 0.1);
}

@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts' as *;

.btn_icon {
  color: $secondary-600;

  &:hover {
    color: $secondary-400;
  }
}
.ant-image-preview-operations-icon {
  svg,
  i {
    filter: brightness(0) invert(1) !important;
  }
}

.userSelector_image {
  width: 2rem;
  height: 2rem;
}
.rc-virtual-list-holder-inner {
  align-items: flex-start !important;
  justify-content: flex-start !important;
}
.ant-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled),
.ant-select-dropdown .ant-select-item-option {
  width: -webkit-fill-available !important;
}
.ant-select-dropdown .ant-select-item-option-content {
  display: flex !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
}
.ant-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled),
.ant-select-dropdown .ant-select-item-option {
  align-items: center !important;
  justify-content: center !important;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  min-height: 50px !important;
  border: 1px solid #e2e8f0 !important;
  background-color: #f8fafc !important;
  border-radius: 8px !important;
  font-family: inherit !important;
}
.userSelector_item {
  display: flex !important;
  align-items: center !important;
}
.ant-select-multiple .ant-select-selection-item {
  height: 38px !important;
  align-items: center !important;
}
.ant-select-dropdown .ant-select-item,
.ant-select-multiple .ant-select-selection-placeholder,
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  transition: none !important;
}

@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts' as *;
@forward './components/BoxAddress/boxAddress';
@forward './components/deliveryStats/deliveryStats';
#user_container {
  .user_form_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
  }

  .user_form_button {
    width: 130px;
    margin-top: 12px;
  }

  .user_row {
    display: flex;
    align-items: flex-start;
    gap: 12px;
  }

  @media only screen and (max-width: 786px) {
    .user_row {
      flex-direction: column;
    }
  }
}

#user_details_container {
  display: flex;
  flex-direction: column;
  gap: 22px;

  .user_details_card_header {
    border-bottom: 1px solid $secondary;
    padding-bottom: 4px;
    font-weight: bold;
    margin-bottom: 22px;
  }

  .user_details_card_info {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 12px;
  }

  .input {
    width: 100%;
    height: 50px;
    border: 1px solid $secondary-200;
    background-color: $light;
    border-radius: 8px;
    padding: 10px 14px;
    font-family: inherit;

    &::placeholder {
      font-weight: 400;
      font-size: 15px;
      color: $secondary-400;
    }
  }

  .user_details_label {
    color: $secondary-500;
    // padding: 0 4px;
  }

  .user_details_inputs {
    margin: 12px 0;
    position: relative;

    .clipboard {
      font-size: 18px;
      position: absolute;
      bottom: 16px;
      right: 18px;
      cursor: pointer;
      color: $secondary-400;

      &:active {
        transform: translateY(4px);
      }
    }
  }

  .no_orders_found {
    text-align: center;
  }
}

// ColumnInfo

.users_label {
  color: $light;
  padding: 2px 12px;
  border-radius: 6px;
  white-space: nowrap;
}

.user_label {
  background-color: $success-600;
}

.admin_label {
  background-color: $danger-600;
}

.delivery_label {
  background-color: $warning-600;
}

.order_item {
  margin-bottom: 15px;
}
.css-1qqsdnr-MuiAutocomplete-root {
  width: -webkit-fill-available !important;
}
.cart {
  &_header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    &-det {
      display: flex;
      flex-direction: column;
      gap: 0.2rem;
      & div,
      div > h1 {
        display: flex;
        gap: 0.1rem;
        font-size: 17.32px;
        line-height: 15px;
        color: #4f46e5;
      }
      div > .old-price {
        text-decoration: line-through;
      }
      & h2 {
        color: #64748b;
      }
    }
  }
  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
  }
  &_promo_code {
    display: flex;
    gap: 0.4rem;
    .success-input {
      border-color: #61ab3c;
      box-shadow: 0 0 0 2px rgba(82, 196, 26, 0.2);
    }
  }
}
.empty_container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product_container {
  &-title {
    display: flex;
    flex-direction: column;

    justify-content: center;
    gap: 0.2rem;
  }
  &-number {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.2rem;
    flex-direction: column;
  }
  &-det {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.7rem;
    &-img {
      width: 40px;
    }
  }
  &-manip {
    align-items: center;
    justify-content: center;

    display: flex;
    gap: 0.2rem;
    &-remove {
      cursor: pointer;
      margin-top: 0.3rem;
      background-color: transparent;
    }
  }
}

.ant-collapse-content-box {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.order_collapse:has(.collapse_cart) {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}
.ant-input-number {
  width: 60px !important;
}
.error-text {
  color: red;
}
.date_range_order {
  & div {
    width: 100%;
  }
}
.order_collapse_container {
  display: flex;
  flex-direction: column;
  gap: 0.9rem;
}
.avatar {
  display: inline-block;
  aspect-ratio: 1/1;
  max-width: 100%;
  border-radius: 50%;
  overflow: hidden;
}

.avatar img,
.avatar .ant-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

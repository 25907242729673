@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts' as *;

#notfound_container {
  min-width: 100vw;
  min-height: 100vh;

  display: flex;
  padding: 32px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 62px;

  .notfound_image {
    width: 40%;
  }

  .notfound_error_message {
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    color: $secondary-500;
  }
}

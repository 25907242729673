@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts' as *;

.dropzone_container {
  height: 142px;
  border-radius: 9px;
  border: 1px dashed $secondary-400;
  display: flex;
  justify-content: center;
  align-items: center;

  .dropzone_title {
    color: $secondary-400;
    text-align: center;
    padding: 0 12px;
    cursor: pointer;
  }

  .image_dropzone {
    max-height: 80%;
  }
}

.error {
  color: $danger;
}

.dropzone_error {
  color: $danger;
  margin: -8px 0;
  margin-bottom: 12px;
}

.is-invalid {
  border: 1px dashed $danger;
}

@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts' as *;

#login_container {
  min-width: 100vw;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $secondary-100;

  .login_logo {
    margin-bottom: 62px;
    background-color: $light;
  }

  .login_card {
    width: 420px;
    margin: 42px 12px;
    padding: 32px;
    background-color: $light;
    border-radius: 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  .login_feature {
    width: 100%;

    .login_form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 22px;
    }

    .login_button {
      margin-top: 12px;
    }
  }

  .login_input {
    background-color: $secondary-100 !important;
  }
}

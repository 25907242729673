@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts' as *;

.loading {
  width: 100%;
  height: 100%;
  position: relative;

  .circle {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 60px;
    width: 60px;
    margin: -25px 0 0 -25px;
    border: 4px rgba(0, 0, 0, 0.25) solid;
    border-top: 4px $primary solid;
    border-radius: 50%;
    -webkit-animation: spin 1s infinite linear;
    animation: spin 1s infinite linear;
  }

  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }

  @keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
}

@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts' as *;

.navbar_container {
  width: 100%;
  max-height: 58px;
  padding: 12px 22px;
  border-radius: 10px;
  background-color: $light;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  position: relative;

  .search_input_container {
    width: 100%;
    height: 100%;
    background-color: $secondary-100;
    border-radius: 10px;
    overflow: hidden;
    padding: 6px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;

    .search_input {
      width: 100%;
      height: 100%;
      font-size: 14px;
      color: $secondary-600;
      background-color: $secondary-100;
    }

    .search_icon {
      color: $secondary-400;
      cursor: pointer;
    }
  }

  .path {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;

    .menu_bars {
      display: none;
      cursor: pointer;
    }
  }

  .settings {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;

    .search {
      cursor: pointer;
      padding: 0 6px;
    }

    .avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      border: 1px solid $secondary-100;
      cursor: pointer;
    }

    .theme_icon {
      width: 28px;
      height: 28px;
      cursor: pointer;
    }

    .theme_icon_flip {
      transform: scale(-1);
      filter: drop-shadow(3px 2px 2px rgb(0 0 0 / 0.4));
    }
  }

  .dropdown_container {
    min-height: 50px;
    min-width: 150px;
    background-color: $light;
    border-radius: 12px;
    padding: 12px 18px;
    position: absolute;
    bottom: -60px;
    right: 0;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    z-index: 99999;

    .buttons {
      text-align: center;
      background-color: transparent;
      padding: 3px 0;
      border-radius: 12px;
      cursor: pointer;

      &:hover {
        background-color: $secondary-100;
        color: $danger;
      }
    }
  }

  .flag {
    cursor: pointer;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid $secondary-100;
  }

  @media only screen and (max-width: 768px) {
    .path {
      .menu_bars {
        display: block;
      }
    }
  }

  @media only screen and (max-width: 480px) {
    .path_title {
      display: none;
    }
  }
}

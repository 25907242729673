@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts' as *;

.sidebar_container {
  min-width: 260px;
  width: 260px;
  height: 100%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 0 15px 15px 0;
  overflow: hidden;
  overflow-y: scroll;
  background-color: $light;

  .logo_container {
    width: 100%;
    padding: 32px 12px 12px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;

    .logo {
      width: 152px;
      height: 44px;
      width: 70%;
      max-width: 160px;
      max-height: 45px;
    }

    .close_btn {
      width: 32px;
      height: 32px;
      display: none;
      background: $light;
      border-radius: 50%;
      cursor: pointer;
      box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

      &:hover {
        color: $danger;
      }
    }
  }

  .menu {
    padding: 12px;
    display: grid;
    gap: 18px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    .logo_container {
      justify-content: space-between;

      .close_btn {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

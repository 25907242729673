@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts' as *;
.revenue {
  &_body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  &_card {
    position: relative;
    flex-direction: column;

    display: flex;
    height: fit-content;
    gap: 1rem;
    min-width: fit-content;
    padding: 10px;
  }
  &_header {
    display: flex;
    gap: 1.3rem;
    flex-wrap: wrap;
    align-items: center;

    &_logo {
      width: 3rem;
    }
    &_title {
      color: $secondary-300;
      font-size: 1rem;
      font-weight: bold;
      letter-spacing: 0.1rem;
    }
  }
  &_quantity {
    color: $secondary-400;
    font-size: 1rem;
    font-weight: bold;
  }
}

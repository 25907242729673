#service_container {
  .service_form_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;

    .service_form_button {
      width: 130px;
      margin-top: 12px;
    }
  }
}
.css-1a4b21o,
.css-1yhpg23-MuiTableCell-root {
  & span {
    & img {
      max-width: 50px !important;
    }
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  20% {
    transform: translateY(10px);
    opacity: 1;
  }
  80% {
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

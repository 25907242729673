#bracket_container {
  .bracket_form_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;

    .bracket_form_button {
      width: 130px;
      margin-top: 12px;
    }
  }
}

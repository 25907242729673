@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts' as *;

.text_field_container {
  position: relative;
  width: 100%;

  .input {
    width: 100%;
    height: 50px;
    border: 1px solid $secondary-200;
    background-color: $light;
    border-radius: 8px;
    padding: 10px 14px;
    font-family: inherit;
    &.disabled,
    &::placeholder {
      font-weight: 400;
      font-size: 15px;
      color: $secondary-400;
    }
  }

  .input:focus {
    outline: none;
  }

  .label {
    display: block;
    font-size: 14px;
    padding: 4px;
    color: $secondary-500;
  }

  .is-invalid {
    border: 1px solid;
    border-color: $danger;
    border-radius: 8px;
  }

  .error {
    position: absolute;
    font-size: 12px;
    color: $danger;
    padding: 4px;
  }

  .input_container {
    position: relative;
  }

  .input-icon {
    position: absolute;
    bottom: 3px;
    right: 12px;
    cursor: pointer;
    color: $secondary-300;
  }
}
.label {
  display: block;
  font-size: 14px;
  padding: 4px;
  color: $secondary-500;
}

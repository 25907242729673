#shipping_cost_container {
  .shipping_cost_form_inputs {
    margin-bottom: 22px;
  }

  .shipping_cost_form_container {
    margin-bottom: 32px;
    display: flex;
    justify-content: center;
    align-items: center;

    .shipping_cost_form_image {
      max-width: 60%;
      max-height: 400px;
    }
  }
}

@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts' as *;
.order_details_inputs {
  margin: 12px 0;
  position: relative;

  .clipboard {
    position: absolute;
    bottom: 10px;
    background-color: transparent;
    border: none;
    right: 18px;
    cursor: pointer;
    color: $secondary-400;
    align-items: center;
    opacity: 0.4;
    transition: all 0.1s ease;
    &:has(.appear) {
      opacity: 1;
    }
    &:hover {
      transform: translateY(-1px);
      opacity: 1;
    }
    &:active {
      transform: translateY(1px);
    }
  }
}

@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts' as *;
@forward './components/revenue';
@forward './components/homeBox/homeBox';
@forward './components/infoDisp/infoDisp';
#dashboard_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .dashboard_title_head {
    font-size: 18px;
    color: $secondary-500;
  }

  .dashboard_subtitle_head {
    color: $secondary-400;
  }
  .dashboard_overview {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .dashboard_card_list_container {
    margin: 42px 0;
    display: grid;
    gap: 22px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .dashboard_card {
    padding: 20px;
    min-height: 200px;
    border-radius: 10px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    transition: all 0.2s;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 12px;

    .dashboard_card_info {
      text-align: center;

      h1 {
        color: $light;
        font-size: 16px;
      }

      h3 {
        color: $light;
        font-size: 14px;
      }
    }
  }

  .dashboard_card:hover {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
    transform: scale(1.01);
  }

  .dashboard_card-1 {
    background: radial-gradient(#1fe4f5, #3fbafe);
  }

  .dashboard_card-2 {
    background: radial-gradient(#fbc1cc, #fa99b2);
  }

  .dashboard_card-3 {
    background: radial-gradient(#60efbc, #58d5c9);
  }

  .dashboard_card_image {
    max-width: 100px;
    max-height: 100px;
    // width: 100px;
    // height: 80px;
    width: 60%;
  }

  @media only screen and (max-width: 720px) {
    .dashboard_card_list_container {
      grid-template-columns: 1fr;
    }
  }

  .dashboard_chart {
    margin-top: 32px;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    &-logo {
      position: absolute;

      right: -14px;
      bottom: -10px;
    }
  }

  .dashboard_chart_Doughnut_Chart {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .dashboard_container_stats {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    gap: 1.2rem;
  }
  .dashboard_container_stats_row_1_2 {
    display: flex;
    gap: 1rem;
    width: 100%;
    flex-direction: column;
  }
  .dashboard_container_stats_row_3-second,
  .dashboard_container_stats_row_1-second {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .dashboard_container_stats_row_1-third {
    display: flex;
    min-width: 50rem;

    gap: 0.5rem;

    & > * {
      width: -webkit-fill-available;
      flex-shrink: 1;
    }
    .total-revenue {
      flex-shrink: 2;
    }
  }

  .dashboard_container_stats_row_3,
  .dashboard_container_stats_row_2,
  .dashboard_container_stats_row_1 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
    & > * {
      width: 100%;
      flex-basis: 0;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
  }
  .dashboard_container_stats_row_1-second {
    & .revenue_card {
      height: 100% !important;
      justify-content: start !important;
    }
    & * > .children-revenue-container:has(.noDataFound) {
      margin-top: -3rem;
    }
  }
  #chart {
    width: 100%;
  }
  .children-revenue-container:has(div[data-chart-source-type='G2Plot']) {
    margin-top: 1.5rem;
  }
}
.g2-html-annotation {
  font-size: 1rem !important;
}
.noDataFound {
  width: 80px;
  padding-top: 1rem;
}
.children-revenue-container:has(.loading) {
  height: 70px;
}
.children-revenue-container:has(.loading),
.children-revenue-container:has(.noDataFound) {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
}

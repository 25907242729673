.ant-select-dropdown .ant-select-item-option-content {
  overflow: unset !important;
}
.ant-select-dropdown .ant-select-item-option-content {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
}
.ant-select-item {
  padding: 0 !important;
}
.rc-virtual-list-holder-inner {
  align-items: center !important;
  justify-content: center !important;
}
.ant-select-dropdown {
  width: unset !important;
}

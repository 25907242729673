@use '../abstracts' as *;

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  font-family: 'Nunito', 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  outline: none;
  border: none;
  color: $dark;
  letter-spacing: 0.025em;
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 1.5;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 5rem;
  overflow-x: hidden;
  font-size: 107.5%;

  ::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(66, 66, 66, 0.2);
    border: 0px;
    background-clip: padding-box;
    border-radius: 5px;
  }
}

.toast-margin {
  margin: 12px;
}
@media (max-width: '340px') {
  * {
    font-size: 0.9rem !important;
  }
}

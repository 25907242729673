.dark_mode {
  .dark_mode_label {
    width: 55px;
    height: 25px;
    position: relative;
    display: block;
    background: #ebebeb;
    border-radius: 200px;
    box-shadow: inset 0px 5px 15px rgba(0, 0, 0, 0.2), inset 0px -5px 15px rgba(255, 255, 255, 0.4);
    cursor: pointer;
    transition: 0.3s;
  }

  .dark_mode_label:after {
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    top: 3px;
    left: 3px;
    background: linear-gradient(180deg, #ffcc89, #d8860b);
    border-radius: 180px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
  }

  .dark_mode_input {
    width: 0;
    height: 0;
    visibility: hidden;
    display: none;
  }

  .dark_mode_input:checked + .dark_mode_label {
    background: #242424;
  }

  .dark_mode_input:checked + .dark_mode_label:after {
    left: 52px;
    transform: translateX(-100%);
    background: linear-gradient(180deg, #777, #3a3a3a);
  }

  .dark_mode_label:active:after {
    width: 25px;
  }

  .dark_mode_label svg {
    position: absolute;
    width: 15px;
    top: 5px;
    left: 5px;
    z-index: 100;
  }

  .dark_mode_label svg.sun {
    left: 5px;
    fill: #fff;
    transition: 0.3s;
  }

  .dark_mode_label svg.moon {
    left: 35px;
    fill: #7e7e7e;
    transition: 0.3s;
  }

  .dark_mode_input:checked + .dark_mode_label svg.sun {
    fill: #7e7e7e;
  }

  .dark_mode_input:checked + .dark_mode_label svg.moon {
    fill: #fff;
  }
}

.ant-select-selection-item:after {
  display: none !important;
}

.ant-select-selector {
  height: fit-content !important;
}

.allUsers-check {
  display: flex;
  align-items: center;
  justify-self: center;
  gap: 1rem;
}
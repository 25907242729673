@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts' as *;

#order_container {
  .order_form_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
  }

  .order_form_button {
    width: 130px;
    margin-top: 12px;
  }

  .order_row {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
  }

  @media only screen and (max-width: 786px) {
    .order_row {
      flex-direction: column;
    }
  }
}

#order_details_container {
  display: flex;
  flex-direction: column;
  gap: 22px;
  .order_products_card_header,
  .order_details_card_header {
    border-bottom: 1px solid $secondary;
    padding-bottom: 4px;
    font-weight: bold;
    margin-bottom: 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .order_details_card_info {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 12px;
  }

  .input {
    width: 100%;
    height: 50px;
    border: 1px solid $secondary-200;
    background-color: $light;
    border-radius: 8px;
    padding: 10px 14px;
    font-family: inherit;

    &::placeholder {
      font-weight: 400;
      font-size: 15px;
      color: $secondary-400;
    }
  }

  .order_details_label {
    color: $secondary-500;
    // padding: 0 4px;
  }

  .no_orders_found {
    text-align: center;
  }
}
.tooltip {
  position: absolute;

  left: -22px;
  background: #373737;
  padding: 3px 7px;
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  border-radius: 4px;
  letter-spacing: 1px;
  opacity: 0;

  &.appear {
    animation: appear 1s ease;
  }
}

// ColumnInfo

.order_label {
  color: $light;
  padding: 2px 12px;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  white-space: nowrap;
}

.is_paid_label {
  background-color: $success-600;
}

.is_not_paid_label {
  background-color: $danger-600;
}

.expandable {
  background: #fff;
  overflow: hidden;
  color: #000;
  line-height: 50px;

  transition: all 0.5s ease-in-out;
  height: 0;
}

.expandable:target {
  height: 50px;
}
.down_arrow_btn {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.order_collapse {
  display: grid;
  grid-template-rows: repeat(1fr, 250px);
  gap: 0.6rem;
  grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
}
.order_details_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
@media (max-width: '527px') {
  .order_collapse {
    display: flex;
    flex-direction: column;
  }
  #order_details_container .input::placeholder {
    font-size: 0.7rem;
  }
}
@media (max-width: '1200px') {
  .order_collapse {
    grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
  }
}

.ant-space-item {
  cursor: pointer !important;
  & .order-status-option {
    border: 1px solid rgb(185, 171, 171);
    padding: 4px 7px;
    border-radius: 8px;
  }
}
.delivery_selectors {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.sm {
  width: fit-content !important;
}
.plan-containers {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.card_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  padding: 22px;

  .search_input {
    width: 100%;
    max-width: 250px;
    min-height: 44px;
    padding: 0 12px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
}

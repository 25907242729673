@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts' as *;

#box_container {
  .box_form_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
  }

  .box_form_button {
    width: 130px;
    margin-top: 12px;
  }
}

.box_label_success {
  color: $success-600;
}

.box_label_danger {
  color: $danger;
}
.qr-code-pic {
  cursor: pointer;
}

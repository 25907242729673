@use 'src/assets/styles/base';
@use 'src/assets/styles/abstracts' as *;

$dark-1: #010409;
$dark-2: #161b22;
$dark-3: #0d1117;

$light-1: #c9d1d9;
$light-2: #8b949e;

$dark-border-color: #30363d;

#dark,
body:has(#root > .App#dark) {
  background-color: $dark-1 !important;

  .sidebar_container {
    background-color: $dark-2 !important;
  }

  .nav_item_container {
    .sub_header,
    .icon_color,
    .item {
      color: $light-1 !important;
    }
  }

  .navbar_container {
    background-color: $dark-2 !important;

    .menu_bars,
    .path_icon {
      color: $light-1 !important;
    }

    .path_title {
      color: $light-1 !important;
    }

    .theme_icon {
      color: $light-1 !important;
    }
  }

  .card_component_container {
    background-color: $dark-2 !important;
    border-radius: 12px !important;
    box-shadow: none !important;
  }

  .card_container {
    background-color: $dark-2 !important;
    box-shadow: none !important;
  }
  .order_details_label {
    color: $light-2 !important;
  }

  .text_field_container {
    .label {
      color: $light-2 !important;
    }
    .ant-select-show-search .ant-select-selector,
    .input {
      background-color: $dark-3 !important;
      border: 1px solid $dark-border-color !important;
      color: $light-1 !important;
    }
    .ant-select-multiple .ant-select-selection-item {
      background-color: #cab6b6 !important;
    }
    .is-invalid {
      border-color: $danger !important;
    }
    .ant-select-selection-placeholder {
      color: $light-2 !important;
    }
    .ant-select-selection-search {
      color: $light-1 !important;
    }
  }

  .card_header {
    background-color: $dark-2 !important;
    border-radius: 12px !important;

    .search_input {
      background-color: $dark-3 !important;
      border: 1px solid $dark-border-color !important;
      color: $light-1 !important;
    }
  }

  .ant-select-arrow > * {
    filter: invert(94%) sepia(11%) saturate(297%) hue-rotate(180deg) brightness(92%) contrast(84%) !important;
  }
  .btn-outline-secondary {
    background-color: $dark-3 !important;
  }

  .MuiTablePagination-selectLabel,
  .MuiSelect-select,
  .MuiTablePagination-displayedRows {
    color: $light-2 !important;
  }

  .MuiSvgIcon-root {
    filter: invert(70%) !important;
  }

  .MuiTableCell-root,
  .MuiTableCell-head {
    background-color: $dark-2 !important;
    color: $light-1 !important;

    border-color: $dark-border-color !important;

    span {
      color: $light-1 !important;
    }
  }

  .columns_container {
    .MuiTypography-root,
    .MuiTypography-body1,
    .MuiFormControlLabel-label {
      color: $light-2 !important;
    }
  }

  .MuiPaper-root {
    background-color: $dark-2 !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: $light-2 !important;
  }

  .MuiFormLabel-root,
  .MuiInputBase-input,
  .MuiChip-label {
    color: $light-2 !important;
  }

  .MuiTypography-root {
    color: $light-2 !important;
  }

  .MuiChip-filled {
    background-color: $dark-1 !important;
  }

  .Mui-error {
    .MuiOutlinedInput-notchedOutline {
      border-color: $danger !important;
    }
  }

  .MuiCircularProgress-colorPrimary {
    filter: invert(50%) !important;
  }

  .MuiAvatar-circular {
    border: 1px solid #333 !important;
  }

  .button_container {
    .delete_btn {
      background-color: $dark-2 !important;
    }
  }

  .user_href {
    color: $light-1 !important;
  }

  .user_details_inputs,
  .order_details_inputs {
    .input {
      background-color: $dark-2 !important;
      border-color: $dark-border-color !important;
    }
  }

  .user_details_card_header,
  .order_details_card_header {
    color: $light-2 !important;
    border-bottom: 1px solid $dark-border-color !important;
  }

  .login_input {
    border-color: $secondary-200 !important;
    border-color: $secondary-200 !important;
  }

  .is-invalid {
    border-color: $danger !important;
  }

  .flag {
    border: 1px solid #333 !important;
  }

  .reset-no-data-found-btn,
  .no_orders_found,
  .darg-and-drop-icon {
    color: $light-2 !important;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent !important;
  }

  ::-webkit-scrollbar {
    width: 8px !important;
    background-color: transparent !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgb(129 128 139 / 23%) !important;
    border: 0px !important;
    background-clip: padding-box !important;
    border-radius: 5px !important;
  }

  .ant-collapse-content-box {
    background-color: $dark-2 !important;
  }
  .ant-collapse {
    border-color: $dark-border-color !important;
    border-bottom: 1px solid $dark-border-color !important;

    box-shadow: none !important;
  }

  .ant-collapse .ant-collapse-content {
    border-top: 1px solid $dark-border-color !important;
    overflow: hidden !important;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    filter: brightness(0) saturate(100%) invert(95%) sepia(3%) saturate(901%) hue-rotate(179deg)
      brightness(90%) contrast(89%) !important;
  }
  .clipboard > svg {
    filter: brightness(0) saturate(100%) invert(95%) sepia(3%) saturate(901%) hue-rotate(179deg)
      brightness(90%) contrast(89%) !important;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
    color: $light-2 !important;
  }
  .tooltip {
    background-color: $light-2 !important;
    color: $dark-3 !important;
  }

  .ant-collapse > .ant-collapse-item {
    border-color: $dark-border-color !important;
  }
  .product_container {
    background-color: $dark-2;
    border: 1px solid $dark-border-color !important;
  }
  .ant-collapse {
    border: 1px solid $dark-border-color !important;
  }
  .ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid $dark-border-color !important;
  }
  .ant-collapse .ant-collapse-content {
    background-color: $dark-2 !important;
  }
  .ant-divider-horizontal.ant-divider-with-text::after,
  .ant-divider-horizontal.ant-divider-with-text::before {
    border-color: $dark-border-color !important;
  }
  .ant-divider .ant-divider-inner-text {
    color: $light-2 !important;
  }
  .ant-select-selection-item-content {
    color: $light-2 !important;
  }
  .fa-caret-down:before {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(106deg) brightness(107%) contrast(101%);
  }
  .ant-picker-dropdown .ant-picker-panel-container,
  .ant-picker {
    background: $dark-3 !important;
    border: 1px solid $dark-border-color !important;
    color: $light-1 !important;
  }
  .ant-picker-dropdown .ant-picker-panel-container {
    background: $dark-3 !important;
    border: 1px solid $dark-border-color !important;
    color: $light-1 !important;
  }
  .ant-picker .ant-picker-input,
  input,
  input::placeholder {
    color: $light-1 !important;
  }
  .stats-card-true .icon svg {
    background: $dark-3 !important;
    fill: $light-1 !important;
  }
  .label {
    color: $light-2 !important;
  }
  .ant-radio-button-wrapper > span {
    color: $light-1 !important;
  }
  label.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) {
    background-color: $dark-3 !important;
  }
  .ant-modal-header {
    background-color: $dark-3 !important;
  }
  .ant-modal .ant-modal-content {
    background-color: $dark-3 !important;
    border: 1px solid $dark-border-color !important;
    color: $light-1 !important;
  }
  .product_container-title,
  .product_container-title > *,
  .ant-input-number-group-addon,
  .cart__title {
    color: $light-1 !important;
  }
  .ant-input-number-input-wrap {
    background-color: $dark-3 !important;
  }
  .input {
    color: $light-1 !important;
  }
  .ant-dropdown .ant-dropdown-menu {
    & li span span {
      color: $light-1 !important;
    }
    background-color: $dark-3 !important;
  }
  .MuiAutocomplete-popper {
    & > * > * {
      background-color: $dark-3 !important;
    }

    & > * > * > * {
      color: $light-1 !important;
    }
  }
  .MuiPaper-root
    .MuiPaper-elevation
    .MuiPaper-rounded
    .MuiPaper-elevation1
    .MuiPaper-root
    .MuiMenu-paper
    .MuiPaper-elevation
    .MuiPaper-rounded
    .MuiPaper-elevation0
    .MuiPopover-paper {
    &,
    &:before {
      background-color: $dark-2 !important;
    }
    & > ul > hr,
    li {
      color: $light-1 !important;
    }
  }
  .MuiPopover-paper {
    & > ul > hr,
    li {
      color: $light-1 !important;
    }
    & > ul > hr {
      background-color: $light-1 !important;
    }
    &:before {
      background-color: $dark-2 !important;
    }
  }
  .btn_icon,
  .fa-right-from-bracket {
    color: $light-1 !important;
  }
  h4,
  .g2-html-annotation {
    color: $light-1 !important;
  }
  .ant-picker-panel-layout {
    & > * > * > *,
    & > * > * > * > * > * > *,
    & > * > * > * > * > * > * > *,
    & > * > * > * > * > * > * > * > * > *,
    & > * > * > * > * > * > * > * > * > * > * {
      color: $light-1 !important;
    }
  }

  .ant-picker-cell-in-range::before {
    background-color: $dark-1;
  }
  .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: $dark-2 !important;
  }
  .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start::before,
  .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end::before {
    background-color: $dark-2 !important;
  }
}

$dark: #111827;
$light: #f8fafc;

$secondary: #cbd5e1;
$secondary-100: #f1f5f9;
$secondary-200: #e2e8f0;
$secondary-300: #cbd5e1;
$secondary-400: #94a3b8;
$secondary-500: #64748b;
$secondary-600: #475569;
$secondary-700: #334155;
$secondary-800: #1e293b;
$secondary-900: #0f172a;

$warning: #ea580c;
$warning-100: #ffedd5;
$warning-200: #fed7aa;
$warning-300: #fdba74;
$warning-400: #fb923c;
$warning-500: #f97316;
$warning-600: #ea580c;
$warning-700: #c2410c;
$warning-800: #9a3412;
$warning-900: #7c2d12;

$success: #22c55e;
$success-100: #dcfce7;
$success-200: #bbf7d0;
$success-300: #86efac;
$success-400: #4ade80;
$success-500: #22c55e;
$success-600: #16a34a;
$success-700: #15803d;
$success-800: #166534;
$success-900: #14532d;

$info: #22d3ee;
$info-100: #cffafe;
$info-200: #a5f3fc;
$info-300: #67e8f9;
$info-400: #22d3ee;
$info-500: #06b6d4;
$info-600: #0891b2;
$info-700: #0e7490;
$info-800: #155e75;
$info-900: #164e63;

$primary: #4f46e5;
$primary-100: #e0e7ff;
$primary-200: #c7d2fe;
$primary-300: #a5b4fc;
$primary-400: #818cf8;
$primary-500: #6366f1;
$primary-600: #4f46e5;
$primary-700: #4338ca;
$primary-800: #3730a3;
$primary-900: #312e81;

$danger: #dc2626;
$danger-100: #fef2f2;
$danger-200: #fecaca;
$danger-300: #fca5a5;
$danger-400: #f87171;
$danger-500: #ef4444;
$danger-600: #dc2626;
$danger-700: #b91c1c;
$danger-800: #991b1b;
$danger-900: #7f1d1d;
